(function (root, factory) {
    var routing = factory();
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define([], routing.Routing);
    } else if (typeof module === 'object' && module.exports) {
        // Node. Does not work with strict CommonJS, but
        // only CommonJS-like environments that support module.exports,
        // like Node.
        module.exports = routing.Routing;
    } else {
        // Browser globals (root is window)
        root.Routing = routing.Routing;
        root.fos = {
            Router: routing.Router
        };
    }
}(this, function () {
    var exports = {};
    "use strict";
exports.__esModule = true;
exports.Routing = exports.Router = void 0;
var Router = /** @class */ (function () {
    function Router(context, routes) {
        this.context_ = context || { base_url: '', prefix: '', host: '', port: '', scheme: '', locale: '' };
        this.setRoutes(routes || {});
    }
    Router.getInstance = function () {
        return exports.Routing;
    };
    Router.setData = function (data) {
        var router = Router.getInstance();
        router.setRoutingData(data);
    };
    Router.prototype.setRoutingData = function (data) {
        this.setBaseUrl(data['base_url']);
        this.setRoutes(data['routes']);
        if (typeof data.prefix !== 'undefined') {
            this.setPrefix(data['prefix']);
        }
        if (typeof data.port !== 'undefined') {
            this.setPort(data['port']);
        }
        if (typeof data.locale !== 'undefined') {
            this.setLocale(data['locale']);
        }
        this.setHost(data['host']);
        if (typeof data.scheme !== 'undefined') {
            this.setScheme(data['scheme']);
        }
    };
    Router.prototype.setRoutes = function (routes) {
        this.routes_ = Object.freeze(routes);
    };
    Router.prototype.getRoutes = function () {
        return this.routes_;
    };
    Router.prototype.setBaseUrl = function (baseUrl) {
        this.context_.base_url = baseUrl;
    };
    Router.prototype.getBaseUrl = function () {
        return this.context_.base_url;
    };
    Router.prototype.setPrefix = function (prefix) {
        this.context_.prefix = prefix;
    };
    Router.prototype.setScheme = function (scheme) {
        this.context_.scheme = scheme;
    };
    Router.prototype.getScheme = function () {
        return this.context_.scheme;
    };
    Router.prototype.setHost = function (host) {
        this.context_.host = host;
    };
    Router.prototype.getHost = function () {
        return this.context_.host;
    };
    Router.prototype.setPort = function (port) {
        this.context_.port = port;
    };
    Router.prototype.getPort = function () {
        return this.context_.port;
    };
    ;
    Router.prototype.setLocale = function (locale) {
        this.context_.locale = locale;
    };
    Router.prototype.getLocale = function () {
        return this.context_.locale;
    };
    ;
    /**
     * Builds query string params added to a URL.
     * Port of jQuery's $.param() function, so credit is due there.
     */
    Router.prototype.buildQueryParams = function (prefix, params, add) {
        var _this = this;
        var name;
        var rbracket = new RegExp(/\[\]$/);
        if (params instanceof Array) {
            params.forEach(function (val, i) {
                if (rbracket.test(prefix)) {
                    add(prefix, val);
                }
                else {
                    _this.buildQueryParams(prefix + '[' + (typeof val === 'object' ? i : '') + ']', val, add);
                }
            });
        }
        else if (typeof params === 'object') {
            for (name in params) {
                this.buildQueryParams(prefix + '[' + name + ']', params[name], add);
            }
        }
        else {
            add(prefix, params);
        }
    };
    /**
     * Returns a raw route object.
     */
    Router.prototype.getRoute = function (name) {
        var prefixedName = this.context_.prefix + name;
        var sf41i18nName = name + '.' + this.context_.locale;
        var prefixedSf41i18nName = this.context_.prefix + name + '.' + this.context_.locale;
        var variants = [prefixedName, sf41i18nName, prefixedSf41i18nName, name];
        for (var i in variants) {
            if (variants[i] in this.routes_) {
                return this.routes_[variants[i]];
            }
        }
        throw new Error('The route "' + name + '" does not exist.');
    };
    /**
     * Generates the URL for a route.
     */
    Router.prototype.generate = function (name, opt_params, absolute) {
        var route = (this.getRoute(name));
        var params = opt_params || {};
        var unusedParams = Object.assign({}, params);
        var url = '';
        var optional = true;
        var host = '';
        var port = (typeof this.getPort() == 'undefined' || this.getPort() === null) ? '' : this.getPort();
        route.tokens.forEach(function (token) {
            if ('text' === token[0] && typeof token[1] === 'string') {
                url = Router.encodePathComponent(token[1]) + url;
                optional = false;
                return;
            }
            if ('variable' === token[0]) {
                if (token.length === 6 && token[5] === true) { // Sixth part of the token array indicates if it should be included in case of defaults
                    optional = false;
                }
                var hasDefault = route.defaults && !Array.isArray(route.defaults) && typeof token[3] === 'string' && (token[3] in route.defaults);
                if (false === optional || !hasDefault || ((typeof token[3] === 'string' && token[3] in params) && !Array.isArray(route.defaults) && params[token[3]] != route.defaults[token[3]])) {
                    var value = void 0;
                    if (typeof token[3] === 'string' && token[3] in params) {
                        value = params[token[3]];
                        delete unusedParams[token[3]];
                    }
                    else if (typeof token[3] === 'string' && hasDefault && !Array.isArray(route.defaults)) {
                        value = route.defaults[token[3]];
                    }
                    else if (optional) {
                        return;
                    }
                    else {
                        throw new Error('The route "' + name + '" requires the parameter "' + token[3] + '".');
                    }
                    var empty = true === value || false === value || '' === value;
                    if (!empty || !optional) {
                        var encodedValue = Router.encodePathComponent(value);
                        if ('null' === encodedValue && null === value) {
                            encodedValue = '';
                        }
                        url = token[1] + encodedValue + url;
                    }
                    optional = false;
                }
                else if (hasDefault && (typeof token[3] === 'string' && token[3] in unusedParams)) {
                    delete unusedParams[token[3]];
                }
                return;
            }
            throw new Error('The token type "' + token[0] + '" is not supported.');
        });
        if (url === '') {
            url = '/';
        }
        route.hosttokens.forEach(function (token) {
            var value;
            if ('text' === token[0]) {
                host = token[1] + host;
                return;
            }
            if ('variable' === token[0]) {
                if (token[3] in params) {
                    value = params[token[3]];
                    delete unusedParams[token[3]];
                }
                else if (route.defaults && !Array.isArray(route.defaults) && (token[3] in route.defaults)) {
                    value = route.defaults[token[3]];
                }
                host = token[1] + value + host;
            }
        });
        url = this.context_.base_url + url;
        if (route.requirements && ('_scheme' in route.requirements) && this.getScheme() != route.requirements['_scheme']) {
            var currentHost = host || this.getHost();
            url = route.requirements['_scheme'] + '://' + currentHost + (currentHost.indexOf(':' + port) > -1 || '' === port ? '' : ':' + port) + url;
        }
        else if ('undefined' !== typeof route.schemes && 'undefined' !== typeof route.schemes[0] && this.getScheme() !== route.schemes[0]) {
            var currentHost = host || this.getHost();
            url = route.schemes[0] + '://' + currentHost + (currentHost.indexOf(':' + port) > -1 || '' === port ? '' : ':' + port) + url;
        }
        else if (host && this.getHost() !== host + (host.indexOf(':' + port) > -1 || '' === port ? '' : ':' + port)) {
            url = this.getScheme() + '://' + host + (host.indexOf(':' + port) > -1 || '' === port ? '' : ':' + port) + url;
        }
        else if (absolute === true) {
            url = this.getScheme() + '://' + this.getHost() + (this.getHost().indexOf(':' + port) > -1 || '' === port ? '' : ':' + port) + url;
        }
        if (Object.keys(unusedParams).length > 0) {
            var queryParams_1 = [];
            var add = function (key, value) {
                // if value is a function then call it and assign it's return value as value
                value = (typeof value === 'function') ? value() : value;
                // change null to empty string
                value = (value === null) ? '' : value;
                queryParams_1.push(Router.encodeQueryComponent(key) + '=' + Router.encodeQueryComponent(value));
            };
            for (var prefix in unusedParams) {
                if (unusedParams.hasOwnProperty(prefix)) {
                    this.buildQueryParams(prefix, unusedParams[prefix], add);
                }
            }
            url = url + '?' + queryParams_1.join('&');
        }
        return url;
    };
    /**
     * Returns the given string encoded to mimic Symfony URL generator.
     */
    Router.customEncodeURIComponent = function (value) {
        return encodeURIComponent(value)
            .replace(/%2F/g, '/')
            .replace(/%40/g, '@')
            .replace(/%3A/g, ':')
            .replace(/%21/g, '!')
            .replace(/%3B/g, ';')
            .replace(/%2C/g, ',')
            .replace(/%2A/g, '*')
            .replace(/\(/g, '%28')
            .replace(/\)/g, '%29')
            .replace(/'/g, '%27');
    };
    /**
     * Returns the given path properly encoded to mimic Symfony URL generator.
     */
    Router.encodePathComponent = function (value) {
        return Router.customEncodeURIComponent(value)
            .replace(/%3D/g, '=')
            .replace(/%2B/g, '+')
            .replace(/%21/g, '!')
            .replace(/%7C/g, '|');
    };
    /**
     * Returns the given query parameter or value properly encoded to mimic Symfony URL generator.
     */
    Router.encodeQueryComponent = function (value) {
        return Router.customEncodeURIComponent(value)
            .replace(/%3F/g, '?');
    };
    return Router;
}());
exports.Router = Router;
exports.Routing = new Router();
exports["default"] = exports.Routing;


    return { Router: exports.Router, Routing: exports.Routing };
}));;


fos.Router.setData({"base_url":"","routes":{"blog_search":{"tokens":[["text","\/blog\/search"]],"defaults":[],"requirements":[],"hosttokens":[],"methods":["GET"],"schemes":[]},"send_a_quote":{"tokens":[["text","\/send-a-quote"]],"defaults":[],"requirements":[],"hosttokens":[],"methods":["POST"],"schemes":[]},"contact_us":{"tokens":[["text","\/contact"]],"defaults":[],"requirements":[],"hosttokens":[],"methods":[],"schemes":[]},"pbx":{"tokens":[["text","\/hosted-pbx"]],"defaults":[],"requirements":[],"hosttokens":[],"methods":[],"schemes":[]},"get_rate":{"tokens":[["text","\/get-rate"]],"defaults":[],"requirements":[],"hosttokens":[],"methods":["GET"],"schemes":[]},"get_rate_countries":{"tokens":[["text","\/get-rate-countries"]],"defaults":[],"requirements":[],"hosttokens":[],"methods":["GET"],"schemes":[]},"get_popup_content":{"tokens":[["text","\/get-popup-content"]],"defaults":[],"requirements":[],"hosttokens":[],"methods":["GET"],"schemes":[]},"get_international_rates":{"tokens":[["text","\/get-international-rates"]],"defaults":[],"requirements":[],"hosttokens":[],"methods":["GET"],"schemes":[]},"get_signup":{"tokens":[["text","\/get_signup"]],"defaults":[],"requirements":[],"hosttokens":[],"methods":["POST"],"schemes":[]},"get_rates":{"tokens":[["text","\/international-rates.csv"]],"defaults":[],"requirements":[],"hosttokens":[],"methods":["GET"],"schemes":[]},"get_uptime":{"tokens":[["text","\/get-uptime"]],"defaults":[],"requirements":[],"hosttokens":[],"methods":[],"schemes":[]},"get_voices":{"tokens":[["text","\/get-voices"]],"defaults":[],"requirements":[],"hosttokens":[],"methods":[],"schemes":[]},"support_search":{"tokens":[["text","\/support\/search"]],"defaults":[],"requirements":[],"hosttokens":[],"methods":["GET"],"schemes":[]},"support_new_request":{"tokens":[["text","\/support\/new_request"]],"defaults":[],"requirements":[],"hosttokens":[],"methods":["POST"],"schemes":[]}},"prefix":"","host":"localhost","port":"","scheme":"https","locale":""});;


$(function () {
    app = new VoiplineApp(appConfig);
    app.init();
});

var VoiplineApp = function (options) {
    var me = this;

    me.init = function () {
        $(window).on('windowResizeBreakpointChanged', function () {
            $('.navbar').css('width', 'calc(100vw - ' + scrollbarWidth + 'px)');
        });

        // init all bootstrap tooltips
        $('[data-toggle="tooltip"]').tooltip();
        // init all popovers
        $('[data-toggle="popover"]').popover();

        // top menu: login menu
        $('.navbar .login-block').hover( function (e) {
            $(this).toggleClass('active');
        });

        $(document).on('click', '.play-video-link', function (event) {
            event.preventDefault();

            var body = $('body');

            var link = $(event.target);

            if (!link.hasClass('play-video-link')) {
                link = link.parents('.play-video-link');
            }

            var videoUrl = link.attr('data-video-url');
            videoUrl = videoUrl.replace("watch?v=", "v/");

            if (videoUrl) {
                var videoId = videoUrl.split('/').pop();

                if (videoId) {
                    var modalTemplate =
                        '<div id="dialog-modal-help-video" class="modal fade fade" tabindex="-1" role="dialog" aria-hidden="true">' +
                        '<div class="modal-dialog modal-lg">' +
                        '<div class="modal-content">' +
                        '<div class="modal-header">' +
                        '<a href="#" class="close-link" data-dismiss="modal" aria-hidden="true">' +
                        '<span class="close-label">close</span>' +
                        '<span class="close-icon icon-cancel-circled2"></span>' +
                        '</a>' +
                        '</div>' +
                        '<div class="modal-body">' +
                        '<div class="video-wrapper-block">' +
                        '<iframe width="560" height="315" src="https://www.youtube.com/embed/' + videoId + '" frameborder="0" allowfullscreen></iframe>' +
                        // '<object style="width: 100%; height: 100%;" data="' + videoUrl + '"></object>' +
                        '</div>' +
                        '</div>' +
                        '</div>' +
                        '</div>' +
                        '</div>';

                    body.append($(modalTemplate));

                    $('#dialog-modal-help-video').modal({
                        backdrop: true
                    });

                    body.one('hidden.bs.modal', '#dialog-modal-help-video', function () {
                        $('#dialog-modal-help-video').remove();
                        $(this).removeData('bs.modal');
                    });
                }
            }
        });

        $('a.faq-question').click(function (e) {
            e.preventDefault();
            var replyBlock = $(this).siblings('.faq-reply-block');

            if ($(this).hasClass('active')) {
                replyBlock.slideUp('fast');

                $(this).siblings('.icon-angle-circled-down').show();
                $(this).siblings('.icon-angle-circled-up').hide();
            } else {
                replyBlock.slideDown('fast');

                $(this).siblings('.icon-angle-circled-down').hide();
                $(this).siblings('.icon-angle-circled-up').show();
            }
            $(this).toggleClass('active');
        });

        // "get a quote" block is common for many pages
        // v3
        $('.get-a-quote-form-block button').click(function (event) {
            event.preventDefault();

            var $button = $(event.target);
            var $form = $button.parents('form');
            var $error_block = $form.find('.error_text');

            var $nameField = $('#get-a-quote-name-field');
            var $emailField = $('#get-a-quote-email-field');
            var $phoneField = $('#get-a-quote-phone-number-field');
            var $emplyeesField = $('#get-a-quote-emplyees-field');
            var $microsoftField = $('input[name="get-a-quote-microsoft-field"]:checked');
            var $callsField = $('#get-a-quote-calls-field');
            var $telemarketingField = $('input[name="get-a-quote-telemarketing-field"]:checked');
            var $sharedTollField = $('#get-a-quote-shared-toll-field');
            var $keepNumbersField = $('input[name="get-a-quote-keep-numbers-field"]:checked');
            var $keepNumbersValue = ''
            var $commentField = $('#get-a-quote-comment-field');

            $keepNumbersField.each(function() {
                $keepNumbersValue += $keepNumbersValue ? ', ' + $(this).val() : $(this).val()
            })

            $form.find('.form-group').removeClass('has-error');
            $form.find('.form-group .help-block').hide();
            $error_block.hide();

            var isFormValid = true;

            if (!$nameField.val()) {
                $nameField.parents('.form-group').addClass('has-error');
                $nameField.parents('.form-group').find('.help-block').show().text('Name is a required field');
                isFormValid = false;
            }

            if (!$emailField.val()) {
                $emailField.parents('.form-group').addClass('has-error');
                $emailField.parents('.form-group').find('.help-block').show().text('eMail address is a required field');
                isFormValid = false;
            } else {
                var pattern = /^([a-z0-9_\.-])+@[a-z0-9-]+\.(([a-z0-9-])+\.)?[a-z]{2,10}$/i;
                if (!pattern.test($emailField.val())) {
                    $emailField.parents('.form-group').addClass('has-error');
                    $emailField.parents('.form-group').find('.help-block').show().text('eMail address is not valid');
                    isFormValid = false;
                }
            }

            if (!$phoneField.val()) {
                $phoneField.parents('.form-group').addClass('has-error');
                $phoneField.parents('.form-group').find('.help-block').show().text('Phone number is a required field');
                isFormValid = false;
            }

            if (isFormValid) {
                // reCaptcha block
                if (options.isUseRecaptcha == '1') {
                    grecaptcha.ready(function () {
                        grecaptcha.execute(options.recaptchaKey, { action: 'send_a_quote' }).then(function (token) {
                            send_a_quote(token);
                        });
                    });
                } else {
                    send_a_quote();
                }
            }

            function send_a_quote(token ) {
                $.ajax({
                    url: Routing.generate('send_a_quote'),
                    type: 'json',
                    method: 'POST',
                    data: {
                        name: $nameField.val(),
                        email: $emailField.val(),
                        phone: $phoneField.val(),
                        emplyees: $emplyeesField.val(),
                        microsoft: $microsoftField.val(),
                        calls: $callsField.val(),
                        telemarketing: $telemarketingField.val(),
                        sharedToll: $sharedTollField.val(),
                        keepNumbers: $keepNumbersValue,
                        comment: $commentField.val(),
                        recaptchaResponse: token
                    },
                    success: function (data) {
                        if ((data['err']) && data['err'].length) {
                            $error_block
                                .html(data['err'])
                                .show();

                        } else {
                            $form.find('> div').hide();
                            $form.find('.submitted-label-block').show();
                            $form.find('.submitted-label-block .help-block').show();

                            var $quoteForm = document.querySelector('.get-a-quote-form-block')
                            var top = $quoteForm.offsetTop + ($quoteForm.offsetHeight / 2) -  (window.innerHeight / 2)
                            window.scrollTo(0, top)

                            setTimeout(function () {
                                $('.animated-tick-block').toggleClass('ticked');
                            }, 200);
                        }
                    }
                });
            }
        });

        if (typeof $zopim !== 'undefined') {
            $zopim(function () {
                $zopim.livechat.setLanguage('en');
            });
        }

        // open zopim chat by clicking any chat link
        $(document).on('click', '.chat-link', function (e) {
            e.preventDefault();

            if (typeof $zopim === 'undefined') {
                //  non-prod environment or loading error
                console.info('Zopim chat is disabled');
                return;
            }

            $zopim(function () {
                $zopim.livechat.window.show();
            });
        });

        // "get a rate" block is common for many pages
        // v3
        $(document).on('click', '.get-rate-form button, .get-rate-form-free-calls button', function (event) {
            event.preventDefault();

            var $button = $(event.target);
            var $form = $button.parents('form');

            var $numberField = $form.find('input');
            var $rateChecker = $form.find('#get-rate-phone-input-popup').length === 1 ? 2 : 1;
            var $resultBlock = $form.parents('.get-rate-column').find('.get-rate-result-block');

            var isFormValid = true;
            $form.find('.form-group').removeClass('has-error');

            if (!$numberField.val()) {
                $numberField.parents('.form-group').addClass('has-error');
                $resultBlock
                    .text('Number is a required field')
                    .show();
                isFormValid = false;
            }

            if (isFormValid) {
                // reCaptcha block
                if (options.isUseRecaptcha == '1') {
                    grecaptcha.ready(function () {
                        grecaptcha.execute(options.recaptchaKey, { action: 'get_rate' }).then(function (token) {
                            get_rate(token);
                        });
                    });
                } else {
                    get_rate();
                }
            }

            function get_rate(token) {
                $button.prop('disabled', true);

                $.ajax({
                    url: Routing.generate('get_rate'),
                    type: 'json',
                    method: 'GET',
                    data: {
                        number: $numberField.val(),
                        rateChecker: $rateChecker,
                        recaptchaResponse: token
                    },
                    success: function(response) {
                        $resultBlock
                            .show()
                            .html(response);
                        $form.parents('.get-rate-column').find('.get-rate-example').css('display', 'none');
                    },
                    complete: function() {
                        $button.prop('disabled', false);
                    }
                });
            }
        });

        // v3
        if ($('.international-call-rates-container').length) {
            // reCaptcha block
            if (options.isUseRecaptcha == '1') {
                grecaptcha.ready(function () {
                    grecaptcha.execute(options.recaptchaKey, { action: 'get_rate_countries' }).then(function (token) {
                        get_rate_countries(token);
                    });
                });
            } else {
                get_rate_countries();
            }

            function get_rate_countries(token) {
                $.ajax({
                    url: Routing.generate('get_rate_countries'),
                    type: 'json',
                    method: 'GET',
                    data: {
                        recaptchaResponse: token
                    },
                    success: function (response) {
                        $('.international-call-rates-container').html(response);

                        if ( location.hash &&
                            (location.hash === '#call-form' || location.hash === '#services')
                        ) {
                            me.smoothScroll(location.hash, 0);
                        }
                    }
                });
            }
        }

        $('.popup-content').each(function (index, el) {
            var type = $(el).data('url');
            if (type) {
                $.ajax({
                    url: Routing.generate('get_popup_content'),
                    type: 'json',
                    method: 'GET',
                    data: {
                        type: type
                    },
                    success: function (response) {
                        $(el).html(response);
                    }
                });
            }
        });

        var $signupPhoneField = $('#signup-phone-field');
        $signupPhoneField.intlTelInput({
            utilsScript: "/js/utils.js?1537727621611",
            preferredCountries: ['ie', 'au', 'nz', 'gb', 'us'],
            nationalMode: true,
            geoIpLookup: function (callback) {
                $.get('http://ipinfo.io', function () {
                }, "jsonp").always(function (resp) {
                    var countryCode = (resp && resp.country) ? resp.country : "";
                    callback(countryCode);
                });
            }
        });

        var $requestPhoneField = $('#request-phone-field');
        if ($requestPhoneField.length) {
            $requestPhoneField.intlTelInput({
                utilsScript: "/js/utils.js?1537727621611",
                preferredCountries: ['ie', 'au', 'nz', 'gb', 'us'],
                nationalMode: true,
                geoIpLookup: function (callback) {
                    $.get('http://ipinfo.io', function () {
                    }, "jsonp").always(function (resp) {
                        var countryCode = (resp && resp.country) ? resp.country : "";
                        callback(countryCode);
                    });
                }
            });
        }

        // v3
        $(document).on('click', '.popup-link[data-content="voice-artist"]', function (event) {
            if (!$('#popup-voice-artist').has('.voices').length) {
                // reCaptcha block
                if (options.isUseRecaptcha == '1') {
                    grecaptcha.ready(function () {
                        grecaptcha.execute(options.recaptchaKey, { action: 'get_voices' }).then(function (token) {
                            get_voices(token);
                        });
                    });
                } else {
                    get_voices();
                }

                function get_voices(token) {
                    $.ajax({
                        url: Routing.generate('get_voices'),
                        type: 'json',
                        method: 'GET',
                        data: {
                            recaptchaResponse: token
                        },
                        success: function (response) {
                            $('#popup-voice-artist').html(response);
                            me.initPlayer();
                        }
                    });
                }
            }
            return false;
        });
        $(document).on('click', '.voice-artist-popup .close-link', function (e) {
            soundManager.stopAll();
        });

        $(document).on('click', '.popup-link, .signup-link, .hubspot-link, .request-info-link', function (event) {
            event.preventDefault();

            var body = $('body');
            var link = $(event.target);
            var type = 'signup';

            if (link.hasClass('signup-link')) {
            } else if (link.hasClass('login-link')) {
                type = 'login';
            } else if (link.hasClass('hubspot-link')) {
                type = 'hubspot';
                $('.loader').show();
            } else {
                if (!link.hasClass('popup-link')) {
                    link = link.parents('.popup-link');
                }
                type = link.attr('data-content');
            }

            if (!type) {
                var link = $(event.target).closest('a');
                if (link.hasClass('login-link')) {
                    type = 'login';
                }
            }

            if (link.hasClass('inner-popup')) {
                $('#dialog-modal .modal-body').appendTo(body).hide();
                $('#dialog-modal').remove();
                body.removeData('bs.modal');
                $('.modal-backdrop').remove();
                body.css('padding-right', '0');
            }

            var modalTemplate =
                '<div id="dialog-modal" class="modal fade popup ' + type + '-popup" tabindex="-1" role="dialog" aria-hidden="true">' +
                '<div class="modal-dialog modal-lg">' +
                '<div class="modal-content">' +
                '<div class="modal-header">' +
                '<a href="#" class="close-link" data-dismiss="modal" aria-hidden="true">' +
                '<span class="icon-cancel"></span><br>' +
                '<span class="close-label">close</span>' +
                '</a>' +
                '</div>' +
                '<div class="modal-body">' +
                '</div>' +
                '</div>' +
                '</div>' +
                '</div>';

            body.append($(modalTemplate));
            $('#popup-' + type).appendTo('#dialog-modal .modal-body').show();
            $('#dialog-modal').find('.signup-result').hide();

            $('#dialog-modal').modal({
                backdrop: true
            });

            body.one('shown.bs.modal', '#dialog-modal', function () {
                // v3
                $('.international-call-rates-section select').change(function (e) {
                    var link = $(e.target.selectedOptions[0]);

                    // reCaptcha block
                    if (options.isUseRecaptcha == '1') {
                        grecaptcha.ready(function () {
                            grecaptcha.execute(options.recaptchaKey, { action: 'get_international_rates' }).then(function (token) {
                                get_international_rates(link, token);
                            });
                        });
                    } else {
                        get_international_rates(link);
                    }

                    function get_international_rates(link, token) {
                        $.ajax({
                            url: Routing.generate('get_international_rates'),
                            type: 'json',
                            method: 'GET',
                            data: {
                                country_id: link.attr('data-content'),
                                country_name: link.attr('data-country'),
                                country_iso: link.attr('data-iso'),
                                recaptchaResponse: token
                            },
                            success: function (response) {
                                $('.international-rates-content').html(response);
                                $('.international-rates-content a[data-toggle="tab"]').on('shown.bs.tab', function (e, ui) {
                                    //console.log(e);
                                    if (e.target.hash === '#tollfree') {
                                        $('.international-rates-content .tollfree-extras').show();
                                        $('.international-rates-content .landline-extras').hide();
                                    } else {
                                        $('.international-rates-content .tollfree-extras').hide();
                                        $('.international-rates-content .landline-extras').show();
                                    }
                                });
                            }
                        });
                    }
                });

                if(type == 'hubspot') {
                    $('.loader').fadeOut(3000);
                }
            });

            body.one('hide.bs.modal', '#dialog-modal', function () {
                $('#popup-' + type).appendTo(body).hide();
            });

            body.one('hidden.bs.modal', '#dialog-modal', function () {
                if (appConfig.isUseRecaptcha == '1') {
                    if (type === 'signup') {
                        //grecaptcha.reset(recaptchaWidgetSignup);
                    } else if (type === 'login') {
                        //grecaptcha.reset(recaptchaWidgetLoginBilling);
                    }
                }
                $('#dialog-modal').remove();
                $(this).removeData('bs.modal');
            });
        });

        // v3
        $(document).on('click', '.get-rates-link', function (event) {
            event.preventDefault();

            var body = $('body');
            var link = $(event.target);
            if (!link.hasClass('get-rates-link')) {
                link = link.parents('.get-rates-link');
            }
            var type = link.attr('data-content');

            // reCaptcha block
            if (options.isUseRecaptcha == '1') {
                grecaptcha.ready(function () {
                    grecaptcha.execute(options.recaptchaKey, { action: 'get_international_rates' }).then(function (token) {
                        get_international_rates(token);
                    });
                });
            } else {
                get_international_rates();
            }

            function get_international_rates(token) {
                $('.international-rates-content .country').hide();
                $('.international-rates-content .landline-extras').html('Loading data...');
                $('.international-rates-content .tollfree-extras').html('Loading data...');
                $('.international-rates-content .tab-content').html('Loading data...');

                $.ajax({
                    url: Routing.generate('get_international_rates'),
                    type: 'json',
                    method: 'GET',
                    data: {
                        country_id: link.attr('data-content'),
                        country_name: link.attr('data-country'),
                        country_iso: link.attr('data-iso'),
                        recaptchaResponse: token
                    },
                    success: function (response) {
                        $('.international-rates-content').html(response);
                        $('.international-rates-content a[data-toggle="tab"]').on('shown.bs.tab', function (e, ui) {
                            if (e.target.hash === '#tollfree') {
                                $('.international-rates-content .tollfree-extras').show();
                                $('.international-rates-content .landline-extras').hide();
                            } else {
                                $('.international-rates-content .tollfree-extras').hide();
                                $('.international-rates-content .landline-extras').show();
                            }
                        });
                    }
                });
            }
        });

        $(document).on('keypress', 'form', function (e) {
            if (e.which === 13) {
                e.preventDefault();
                $(this).find('button').trigger('click');
            }
        });

        // v3
        $(document).on('click', '.signup-form button', function (event) {
            event.preventDefault();

            var $button = $(event.target);
            var $form = $button.parents('form');
            var $firstNameField = $form.find('[name="first_name"]');
            var $lastNameField = $form.find('[name="last_name"]');
            var $companyNameField = $form.find('[name="company_name"]');
            var $emailField = $form.find('[name="email"]');
            var $phoneField = $form.find('[name="phone"]');
            var $agreeField = $form.find('[name="agree"]');

            $form.find('.form-group').removeClass('has-error');
            $form.find('.help-block').hide();

            var isFormValid = true;

            if (!$agreeField.is(':checked')) {
                $agreeField.parents('.form-group').addClass('has-error');
                $agreeField.parents('.form-group').find('.help-block').show().text('You must agree to the Master Service Agreement to proceed');
                isFormValid = false;
            }

            if (!$firstNameField.val()) {
                $firstNameField.parents('.form-group').addClass('has-error');
                $firstNameField.parents('.form-group').find('.help-block').show().text('First Name is a required field');
                isFormValid = false;
            }

            if ($firstNameField.val().length > 50) {
                $firstNameField.parents('.form-group').addClass('has-error');
                $firstNameField.parents('.form-group').find('.help-block').show().text('First Name is too long');
                isFormValid = false;
            }

            if (!$lastNameField.val()) {
                $lastNameField.parents('.form-group').addClass('has-error');
                $lastNameField.parents('.form-group').find('.help-block').show().text('Last Name is a required field');
                isFormValid = false;
            }

            if ($lastNameField.val().length > 50) {
                $lastNameField.parents('.form-group').addClass('has-error');
                $lastNameField.parents('.form-group').find('.help-block').show().text('Last Name is too long');
                isFormValid = false;
            }

            if ($companyNameField.val().length > 255) {
                $companyNameField.parents('.form-group').addClass('has-error');
                $companyNameField.parents('.form-group').find('.help-block').show().text('Company Name is too long');
                isFormValid = false;
            }

            if (!$signupPhoneField.intlTelInput('isValidNumber')) {
                var errorCode = $signupPhoneField.intlTelInput('getValidationError');
                var errorMessage = 'Phone Number is not valid';
                switch (errorCode) {
                    case 4:
                        errorMessage += '. Not a number';
                        break;
                    case 2:
                        errorMessage += '. Too short';
                        break;
                    case 3:
                        errorMessage += '. Too long';
                        break;
                }
                if (errorCode) {
                    $phoneField.parents('.form-group').addClass('has-error');
                    $phoneField.parents('.form-group').find('.help-block').show().text(errorMessage);
                    isFormValid = false;
                }
            }

            if (!$emailField.val()) {
                $emailField.parents('.form-group').addClass('has-error');
                $emailField.parents('.form-group').find('.help-block').show().text('Email is a required field');
                isFormValid = false;
            }

            if ($emailField.val().length > 255) {
                $emailField.parents('.form-group').addClass('has-error');
                $emailField.parents('.form-group').find('.help-block').show().text('Email is too long');
                isFormValid = false;
            }

            if (isFormValid) {
                // reCaptcha block
                if (options.isUseRecaptcha == '1') {
                    grecaptcha.ready(function () {
                        grecaptcha.execute(options.recaptchaKey, { action: 'get_signup' }).then(function (token) {
                            get_signup(token);
                        });
                    });
                } else {
                    get_signup();
                }
            }

            function get_signup(token) {
                $button.button('loading');
                $form.parents('.form-block').find('.signup-result').hide();

                $.ajax({
                    url: Routing.generate('get_signup'),
                    type: 'json',
                    method: 'POST',
                    data: {
                        first_name: $firstNameField.val(),
                        last_name: $lastNameField.val(),
                        company_name: $companyNameField.val(),
                        phone: $signupPhoneField.intlTelInput('getNumber'),
                        email: $emailField.val(),
                        recaptchaResponse: token
                    },
                    success: function (response) {
                        if (response.error) {
                            $form.parents('.form-block').find('.signup-result').show().find('.text').html(response.error);
                        } else {
                            $form.parents('.container').find('.signup-success').show();
                            $form.parents('.form-block').hide();
                        }
                        $button.button('reset');
                    },
                    error: function (response) {
                        $button.button('reset');
                    }
                });
            }
        });

        function scrollToError($form) {
            var el = $form.find('.has-error')[0];
            var diff = $(el).offset().top - $('.request-info-form').offset().top;
            $('#dialog-modal').animate({ scrollTop: 245 + diff }, 500);
        }

        $(document).on('keydown', '.search-form-container input', function (event) {
            if (event.keyCode === 13) {
                $('.search-form-container form').submit();
            }
        });

        me.initPlayer();
        me.initSmoothScrolling();
        me.initAnimations();

        if (window.location.hash) {
            var $el = '';

            switch (window.location.hash) {
                case '#login':
                    $el = $('.login-link:first');
                    break;
                case '#signup':
                    $el = $('.signup-link:first');
                    break;
                case '#porting':
                    $el = $('[data-content="number-porting"]:first');
                    break;
                case '#call-recording-storage':
                    $el = $('[data-content="optional_extras"]:first');
                    break;
                case '#int-numbers':
                    $el = $('[data-content="international-numbers"]:first');
                    break;
                case '#voice':
                    $el = $('[data-content="voice-artist"]:first');
                    break;
                case '#free-calls':
                    $el = $('[data-content="free-calls"]:first');
                    break;
                case '#1800':
                    $el = $('[data-content="numbers-1800"]:first');
                    break;
                case '#demo':
                    $el = $('.hubspot-link:first');
                    break;
            }

            if ($el && $el.length) {
                $el.click();
                window.location.hash = '';
            }
        }

        $('#support_new_request').click(function (e) {
            //window.location.assign('/#request_form');
            $(this).hide();
            $(".new-request-block").show(500);

            return false;
        });

        // v3
        $(document).on('click', '.get-rate-download-block a', function (event) {
            $link = $(this);
            $link.addClass('disabled');
            $error_block = $(this).parents('.get-rate-download-block').find('.error_block');
            $error_block
                .html('Data preparing...')
                .show();

            // reCaptcha block
            if (options.isUseRecaptcha == '1') {
                grecaptcha.ready(function () {
                    grecaptcha.execute(options.recaptchaKey, { action: 'get_rates' }).then(function (token) {
                        downloadRates(token);
                    });
                });
            } else {
                downloadRates();
            }

            function downloadRates(token) {
                document.location.href = Routing.generate('get_rates') + '?recaptchaResponse=' + token;
                // window.open(Routing.generate('get_rates') + '?recaptchaResponse=' + token, '_blank');
                $link.removeClass('disabled');
                $error_block.hide();

                /*
                $.ajax({
                    url: Routing.generate('get_rates'),
                    type: 'json',
                    method: 'GET',
                    data: {
                        recaptchaResponse: token
                    },
                    success: function (data) {
                        if (data['err'].length) {
                            $error_block
                                .html(data['err'])
                                .show();
                        } else {
                            document.location.href = Routing.generate('get_rates') + '?recaptchaResponse=' + token;
                            $error_block.hide();
                        }
                        $link.removeClass('disabled');
                        return false;
                    }
                });
                 */
            }
        });
    };

    me.initPlayer = function () {

        me.inlinePlayer = null;

        soundManager.setup({
            debugMode: false,
            preferFlash: true,
            useFlashBlock: false,
            // path to directory containing SM2 SWF
            url: '../sounds/'
            // optional: enable MPEG-4/AAC support (requires flash 9)
            //flashVersion: 9
        });
        soundManager.onready(function () {
            // soundManager.createSound() etc. may now be called
            me.inlinePlayer = new InlinePlayer();
        });
    };

    me.initAnimations = function () {
        $(window).scroll(function () {
            // add uptime counter animation
            var $uptimePercentageLabelBlock = $('.uptime-percentage-block .percentage-label .percentage-value-block');
            if ($uptimePercentageLabelBlock.length) {
                if (!$uptimePercentageLabelBlock.hasClass('odometer')) {
                    var uptimePercentageLabelTopOffset = $uptimePercentageLabelBlock.offset().top;

                    var topOfWindow = $(window).scrollTop();
                    if (uptimePercentageLabelTopOffset < topOfWindow + 800) {
                        var percentage = $uptimePercentageLabelBlock.attr('data-percentage-value');

                        // odometerOptions = {auto: false};

                        var od = new Odometer({
                            el: $uptimePercentageLabelBlock[0],
                            value: 0,
                            // format: '',
                            theme: 'default'
                        });

                        od.update(percentage);
                    }
                }
            }

            // add background animation to Get a quote block
            var $getAQuoteBlock = $('.get-a-quote-block');
            if ($getAQuoteBlock.length) {
                if (!$getAQuoteBlock.hasClass('slide-left-background')) {
                    var getAQuoteBlockTopOffset = $getAQuoteBlock.offset().top;

                    var topOfWindow = $(window).scrollTop();
                    if (getAQuoteBlockTopOffset < topOfWindow + 300) {
                        $getAQuoteBlock.addClass('slide-left-background');
                    }
                }
            }

        });
    };

    me.initSmoothScrolling = function () {
        $('a[href*="#"]:not([href="#"]):not([data-toggle])').click(function () {
            if ((location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '')) && (location.hostname === this.hostname)) {
                me.smoothScroll(this.hash, 1000);
            }
        });

        if (location.hash) {
            me.smoothScroll(location.hash, 1000);
        }
    };
    me.smoothScroll = function (hash, time) {
        var $topMenuHeight = 0;
        var $bookMeHeight = 0;

        var $isTopMenuFixed =  ($('nav.navbar').css('position') === 'fixed');
        if ($isTopMenuFixed) {
            $topMenuHeight = parseInt($('nav.navbar').css('height'));
        }

        if (hash == '#sip-tab') {
            $('.call-centers-pbx-block a[href="#sip-tab"]').click();

            var offsetTop = $('#sip-tab').offset().top - $topMenuHeight - $bookMeHeight - 110;

            $("html,body").stop().animate ({
                scrollTop: offsetTop
            }, time);

            window.location.hash = '';
            return false;
        }

        var target = $(hash);
        target = target.length ? target : $('[name=' + hash.slice(1) + ']');

        if (target.length) {
            $('html, body').animate({
                scrollTop: target.offset().top - $topMenuHeight - $bookMeHeight
            }, time);
            return false;
        }
    };

    if (options.isUseRecaptcha === '0') {
        activateAllFormsSubmitButtons ();

    } else {
        var recaptchaInterval = setInterval(function () {
            if (typeof grecaptcha !== 'undefined') {
                clearInterval(recaptchaInterval);
                activateAllFormsSubmitButtons ();
            }
        }, 1000);
    }

    function activateAllFormsSubmitButtons () {
        activateSubmitButton('.get-a-quote-block .btn');
        activateSubmitButton('#popup-signup button');
        $('.new-request-block button').removeClass('disabled');

        if ($('.international-call-rates-container').length) {
            var getRateInterval = setInterval(function () {
                if ($('.get-rate-form').length) {
                    clearInterval(getRateInterval);
                    $('.get-rate-form button').removeClass('disabled');
                }
            });
            var downloadRatesInterval = setInterval(function () {
                if ($('.get-rate-download-block').length) {
                    clearInterval(downloadRatesInterval);
                    $('.get-rate-download-block a').removeClass('disabled');
                }
            });
        }
    }

    function activateSubmitButton(button) {
        $(button).prop('disabled', false);
    }

    // event listener: DOM ready
    function addLoadEvent(func) {
        var oldonload = window.onload;
        if (typeof window.onload != 'function') {
            window.onload = func;
        } else {
            window.onload = function() {
                if (oldonload) {
                    oldonload();
                }
                func();
            }
        }
    }
    // call plugin function after DOM ready
    addLoadEvent(function(){
        outdatedBrowser({
            // bgColor: '#f25648',
            // color: '#ffffff',
            lowerThan: 'IE10',
            languagePath: ''
        })
    });

    // v3
    $("#support_page .new-request-block button[name=submit]").click(function (event) {
        var $button = $(event.target);
        var $form = $button.parents('form');

        var $emailField = $('#email');
        var $subjectField = $('#subject');
        var $descriptionField = $('#description');

        $form.find('.has-error .error').hide();
        $form.find('.has-error').removeClass('has-error');

        var isFormValid = true;

        if (!$emailField.val()) {
            $emailField.parent().addClass('has-error').find('.email_empty').show();
            isFormValid = false;
        }

        if (!$subjectField.val()) {
            $subjectField.parent().addClass('has-error').find('.subject_empty').show();
            isFormValid = false;
        }

        if ($subjectField.val().length > 50) {
            $subjectField.parent().addClass('has-error').find('.subject_long').show();
            isFormValid = false;
        }

        if (!$descriptionField.val()) {
            $descriptionField.parent().addClass('has-error').find('.description_empty').show();
            isFormValid = false;
        }

        if ($descriptionField.val().length > 500) {
            $descriptionField.parent().addClass('has-error').find('.description_long').show();
            isFormValid = false;
        }

        if (isFormValid) {
            // reCaptcha block
            if (options.isUseRecaptcha == '1') {
                grecaptcha.ready(function () {
                    grecaptcha.execute(options.recaptchaKey, { action: 'support_new_request' }).then(function (token) {
                        support_new_request(token);
                    });
                });
            } else {
                support_new_request();
            }
        }

        function support_new_request(token) {
            $button.addClass('disabled');

            $.ajax({
                url: Routing.generate('support_new_request'),
                type: 'json',
                method: 'POST',
                data: {
                    email: $emailField.val(),
                    subject: $subjectField.val(),
                    description: $descriptionField.val(),
                    recaptchaResponse: token
                },
                success: function (response) {
                    if (!response.isFormValid) {
                        $form.find("." + response.errorField).show();
                        if (window.scrollY > $form.find("." + response.errorField).offset().top) {
                            $('html, body').animate({
                                scrollTop: $form.find("." + response.errorField).offset().top - 100
                            }, 1000);
                        }
                    } else {
                        $(".new-request-block h3").hide();
                        $form.hide();
                        $('.new-request-block .success').show();
                        if (window.scrollY > $('.new-request-block .success').offset().top) {
                            $('html, body').animate({
                                scrollTop: $('.new-request-block').offset().top - 10
                            }, 1000);
                        }
                    }
                },
                complete: function () {
                    $button.removeClass('disabled');
                    document.body.style.cursor = 'default';
                }
            });
        }

        return false;
    });

    function checkNumberField (e, allowPlus) {
        // Allow: backspace (8), delete (46), tab (9), escape (27), enter (13), .(110) and +(107) on right panel
        //if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110/*, 190*/, 107]) !== -1 ||
        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, /*110 (NumPad .) , 190 (.), 107*/]) !== -1 ||
            // Allow: Ctrl+A
            (e.keyCode == 65 && e.ctrlKey === true) ||
            // Allow: Ctrl+C
            (e.keyCode == 67 && e.ctrlKey === true) ||
            // Allow: Ctrl+X
            (e.keyCode == 88 && e.ctrlKey === true) ||
            // Allow: Ctrl+V
            (e.keyCode == 86 && e.ctrlKey === true) ||
            // Allow: Shift+Insert
            (e.keyCode == 45 && e.shiftKey === true) ||
            // Allow: home, end, left, right, up, down
            (e.keyCode >= 35 && e.keyCode <= 40)) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        // !(e.shiftKey && e.keyCode == 187) means plus
        if (allowPlus) {
            if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105) && !(e.shiftKey && e.keyCode == 187)) {
                e.preventDefault();
            }
        } else {
            if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault();
            }
        }
    }

    // book introduction session
    $(window).load( function () {
        $('.navbar').css('width', 'calc(100vw - ' + scrollbarWidth + 'px)');
    });

    $('#popup-hubspot iframe').load(function () {
        var height = $('#dialog-modal').height() - 160;
        this.style.height = height + 'px';
    });

    $(document).on('click', 'a.view-details', function (e) {
        e.preventDefault();
        var detailsBlock = $(this).siblings('.view-details-text');

        if ($(this).hasClass('active')) {
            detailsBlock.slideUp('fast');

            $(this).children('.icon-angle-circled-down').show();
            $(this).children('.icon-angle-circled-up').hide();
        } else {
            detailsBlock.slideDown('fast');

            $(this).children('.icon-angle-circled-down').hide();
            $(this).children('.icon-angle-circled-up').show();
        }
        $(this).toggleClass('active');
    });

    makeDotdotdot = function(height, $items, containerClass, truncate, isClickable) {
        if (typeof truncate === 'undefined') {
            truncate = 'word';
        }
        if (typeof isClickable === 'undefined') {
            isClickable = true;
        }

        var dddOptions = {
            ellipsis: "\u2026",
            height: height,
            truncate: truncate,
            callback: function( isTruncated ) {
                if (isTruncated) {
                    // set .dot-class to parent container
                    $(this).closest(containerClass).addClass('dot');
                }
            }
        };

        $items.each(function(index, el) {
            var dot = new Dotdotdot(el, dddOptions);

            if (isClickable) {
                $(el).click(function (e) {
                    e.preventDefault();

                    var $clickedItem = $(e.target);
                    if ($clickedItem[0].tagName === 'A' && $clickedItem[0].href) {
                        window.location = $clickedItem[0].href;
                        return;
                    }

                    if ($clickedItem.hasClass('ddd-truncated')) {
                        // open full text
                        dot.API.restore();
                    } else {
                        // truncate text
                        dot.API.truncate();
                    }
                });
            }
        });
    }
};